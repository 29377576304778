import { ReactElement, forwardRef, useState } from 'react'
import {
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { HighlightOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IMaskInput } from 'react-imask'
import theme from '@ndls/enterprise/v5/theme'
import { CONSENT_SEARCH_BY } from 'pages/InviteHome/constants'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const MobileMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function MobileMaskCustom(props: CustomProps, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value: string) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite={false}
      />
    )
  }
)

const CustomerLookup = ({
  isLoading = false,
  onSubmit
}: {
  isLoading: boolean
  onSubmit: ({
    identifier,
    searchBy,
    retrieveQualification
  }: {
    identifier: string
    searchBy?: string
    retrieveQualification?: boolean
  }) => unknown
}): ReactElement => {
  const [searchText, setSearchText] = useState<string>('')
  const [criteriaValue, setCriteriaValue] = useState<string>(
    CONSENT_SEARCH_BY.MOBILE
  )
  const [hasValidationError, setHasValidationError] = useState<boolean>(false)
  const [isFocusedInput, setIsFocusedInput] = useState<boolean>(false)

  const { isClientelingAnniversaryStylingAppEnabled } = useFeatureFlags()

  const searchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target?.value)
  }

  const validateSearchText = (): boolean => {
    const validMobileRegex =
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValid =
      searchText !== '' &&
      (criteriaValue === CONSENT_SEARCH_BY.MOBILE
        ? validMobileRegex.test(searchText)
        : validEmailRegex.test(searchText))

    setHasValidationError(!isValid)
    return isValid
  }

  const shouldShowAnniversaryEligibility =
    isClientelingAnniversaryStylingAppEnabled?.active

  const clearSearchText = () => {
    setSearchText('')
    setHasValidationError(false)
  }

  const onClick = () => {
    if (validateSearchText()) {
      const searchValue =
        criteriaValue == CONSENT_SEARCH_BY.MOBILE
          ? searchText.replace(/[^0-9]/g, '')
          : searchText
      onSubmit({
        identifier: searchValue,
        searchBy: criteriaValue,
        retrieveQualification: shouldShowAnniversaryEligibility
      })
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClick()
    }
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearSearchText()
    setCriteriaValue((event.target as HTMLInputElement).value)
  }

  const handleFocusInput = () => {
    setIsFocusedInput(true)
  }

  const handleBlurInput = () => {
    setIsFocusedInput(false)
  }

  const getSearchFieldLabel = () => {
    if (isFocusedInput || searchText !== '') {
      return ''
    } else {
      return criteriaValue === CONSENT_SEARCH_BY.MOBILE
        ? '(555) 555-5555'
        : 'example@email.com'
    }
  }

  const getHelperText = () => {
    if (hasValidationError) {
      return criteriaValue === CONSENT_SEARCH_BY.MOBILE
        ? 'Enter a valid mobile number'
        : 'Enter a valid email'
    } else {
      return ''
    }
  }

  return (
    <Container maxWidth="xs">
      <Stack spacing={2}>
        <Typography variant="subtitle2" paddingTop="8px">
          Look up customer
        </Typography>
        <RadioGroup
          row
          aria-labelledby="criteria-type"
          name="criteriaType"
          value={criteriaValue}
          onChange={handleRadioChange}
          data-testid="criteria-type"
        >
          <FormControlLabel
            value={CONSENT_SEARCH_BY.MOBILE}
            control={<Radio />}
            label="Mobile number"
            sx={{ textVariant: 'body2', margin: '0px 12px 0px 0px' }}
          />
          <FormControlLabel
            value={CONSENT_SEARCH_BY.EMAIL}
            control={<Radio />}
            label="Email"
            sx={{ textVariant: 'body2', marginLeft: '0px' }}
          />
        </RadioGroup>
        <TextField
          fullWidth
          sx={{ marginTop: '0px !important' }}
          type={criteriaValue === CONSENT_SEARCH_BY.MOBILE ? 'tel' : undefined}
          data-testid="search-textfield"
          label={getSearchFieldLabel()}
          variant="outlined"
          value={searchText}
          onChange={searchTextChange}
          onKeyPress={handleKeyPress}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
          error={hasValidationError}
          helperText={getHelperText()}
          InputLabelProps={{
            style: {
              color: !hasValidationError ? theme.palette.grey[500] : undefined
            }
          }}
          InputProps={{
            inputComponent:
              criteriaValue === CONSENT_SEARCH_BY.MOBILE
                ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (MobileMaskCustom as any)
                : undefined,
            disabled: isLoading,
            endAdornment: searchText !== '' && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear"
                  edge="end"
                  onClick={clearSearchText}
                >
                  <HighlightOff />
                </IconButton>
              </InputAdornment>
            ),
            inputProps: {
              style: {
                fontSize: '1rem',
                color: 'text.primary'
              }
            }
          }}
        />

        <LoadingButton
          loading={isLoading}
          fullWidth
          variant="contained"
          disabled={isLoading}
          onClick={onClick}
          data-testid="lookup-button"
          sx={{
            '& .MuiCircularProgress-root': {
              color: 'primary.main'
            }
          }}
        >
          Look Up
        </LoadingButton>
      </Stack>
    </Container>
  )
}

export default CustomerLookup
