import { Box, Typography } from '@mui/material'
import { SkuPriceT, StylePriceT } from 'types/Product'
import { formatStylePrices, shouldShowPriceRange } from './helpers'

interface PropsT {
  stylePrice: StylePriceT
  skuPrice?: SkuPriceT
}

const Price = ({
  price,
  isSale,
  isStrikeThrough
}: {
  price: string
  isSale: boolean
  isStrikeThrough: boolean
}): JSX.Element => {
  return (
    <Typography
      variant="subtitle2"
      color={isSale ? 'error' : 'text.primary'}
      sx={{ textDecoration: isStrikeThrough ? 'line-through' : 'none' }}
    >
      {price}
    </Typography>
  )
}

const ProductPrice = (props: PropsT): JSX.Element => {
  const { stylePrice } = props

  // TODO: Check availability? If something is sold out, what do we show?

  const formattedStylePrices = formatStylePrices(stylePrice)
  const { totalPriceRange } = formattedStylePrices

  const salePrices =
    formattedStylePrices.promotion || formattedStylePrices.clearance

  if (salePrices) {
    return (
      <Box my={1}>
        <Price
          price={
            shouldShowPriceRange(formattedStylePrices.totalPriceRange)
              ? `${totalPriceRange.min} - ${totalPriceRange.max}`
              : salePrices.min || salePrices.max
          }
          isSale={true}
          isStrikeThrough={false}
        />
        <Price
          price={
            shouldShowPriceRange(formattedStylePrices.regular)
              ? `${formattedStylePrices.regular} - ${formattedStylePrices.regular.max}`
              : formattedStylePrices.regular.min ||
                formattedStylePrices.regular.max
          }
          isSale={false}
          isStrikeThrough={true}
        />
      </Box>
    )
  }

  return (
    <Box my={1}>
      <Price
        price={
          shouldShowPriceRange(totalPriceRange)
            ? `${totalPriceRange.min} - ${totalPriceRange.max}`
            : totalPriceRange.min || totalPriceRange.min
        }
        isSale={false}
        isStrikeThrough={false}
      />
    </Box>
  )
}

export default ProductPrice
