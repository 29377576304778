import { useState } from 'react'
import { useAppSelector } from 'app/hooks'
import MenuList from '@mui/material/MenuList'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CheckroomIcon from '@mui/icons-material/Checkroom'
import HomeIcon from '@mui/icons-material/Home'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useHistory, HashRouter, Route } from 'react-router-dom'
import {
  isBizAdmin,
  isDevAdmin,
  isMessagingUser,
  isStyleAdvisor
} from '../../utils/userPermissions'
import NavMenuItem from 'components/NavMenuItem'
import NavMenuItemExpandable from 'components/NavMenuItemExpandable'
import { twilioSelectors } from 'services/twilioSlice'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

export const MENU_HASH = 'menu'

export type PropsT = {
  onClose: () => void
  selectedIndex: number | null
  setSelectedIndex: (index: number) => void
}

const MenuDrawer = (props: PropsT): JSX.Element => {
  const { onClose, selectedIndex, setSelectedIndex } = props
  const history = useHistory()
  const {
    IsStylistProfileStylingToolEnabled,
    isReportingStylingToolEnabled,
    isHoldsEnabled,
    isPBAppointmentsViewEnabled
  } = useFeatureFlags()

  const goToPath = ({ path, index }: { path: string; index: number }) => {
    const attributes = {
      page: path
    }
    generateNewRelicLogs('menuBarNav', attributes)
    history.push({ pathname: path })
    onClose()
    setSelectedIndex(index)
  }
  const hasUnreadMessages = useAppSelector(
    twilioSelectors.hasUnreadMessagesSelector
  )
  const [isCustomerBookSubNavExpanded, setIsCustomerBookSubNavExpanded] =
    useState(true)

  const isPermittedToViewMessageIndicator = isMessagingUser()

  const shouldShowMessageIndicator =
    isPermittedToViewMessageIndicator &&
    hasUnreadMessages &&
    !isCustomerBookSubNavExpanded

  const shouldShowAppointments = isPBAppointmentsViewEnabled?.active

  const MenuOptions = [
    {
      title: 'Home',
      path: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Style boards',
      path: '/styleboards',
      icon: <DashboardCustomizeIcon />
    },
    isReportingStylingToolEnabled?.active && {
      title: 'Reporting',
      path: '/reporting',
      icon: <AutoGraphIcon />
    },
    !isStyleAdvisor() && {
      title: 'Customer book',
      path: '/customers',
      icon: (
        <Badge
          data-testid="customer-book-badge"
          color="primary"
          variant="dot"
          invisible={!shouldShowMessageIndicator}
        >
          <ImportContactsIcon />
        </Badge>
      ),
      hasSubMenu: true
    },
    shouldShowAppointments && {
      title: 'Appointments',
      path: '/appointments',
      icon: <CalendarMonthIcon />
    },
    IsStylistProfileStylingToolEnabled?.active && {
      title: 'Profile & Settings',
      path: '/settings',
      icon: <AccountCircleIcon />
    },
    (isDevAdmin() || isBizAdmin()) && {
      title: 'Permissions Admin',
      path: '/permissions/admin',
      icon: <SupervisorAccountIcon />
    },
    isHoldsEnabled?.active && {
      title: 'Holds',
      path: '/holds',
      icon: <CheckroomIcon />
    }
  ]

  return (
    <Box sx={{ width: '320px' }}>
      <MenuList>
        {MenuOptions.map((option, index) => {
          const path = option ? option.path : ''
          const isSelected = selectedIndex
            ? index === selectedIndex
            : (window.location.pathname.startsWith(path) && path !== '/') ||
              window.location.pathname === path
          return (
            option && (
              <Box key={option.title}>
                {option.hasSubMenu ? (
                  <NavMenuItemExpandable
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    totalMainMenuOptions={MenuOptions.length}
                    icon={option.icon}
                    title={option.title}
                    path={path}
                    index={index}
                    isCustomerBookSubNavExpanded={isCustomerBookSubNavExpanded}
                    setIsCustomerBookSubNavExpanded={
                      setIsCustomerBookSubNavExpanded
                    }
                    goToPath={goToPath}
                    isSelected={isSelected}
                  />
                ) : (
                  <NavMenuItem
                    icon={option.icon}
                    title={option.title}
                    path={path}
                    index={index}
                    goToPath={() => goToPath({ path: option.path, index })}
                    isSelected={isSelected}
                  />
                )}
              </Box>
            )
          )
        })}
      </MenuList>

      <HashRouter basename={MENU_HASH} hashType="noslash">
        <Route exact path="/"></Route>
      </HashRouter>
    </Box>
  )
}

export default MenuDrawer
