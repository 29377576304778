import { ReactElement } from 'react'
import { Typography, Box, Link } from '@mui/material'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'

const AppointmentsList = (): ReactElement => {
  return (
    <BoxWithGladlySpace>
      <Box px={2} mt={4}>
        <Typography variant="h6">Appointments List</Typography>
        <Typography variant="body2" mt={2}>
          Showing your appointments for the next 3 weeks.
        </Typography>
      </Box>

      <Box px={2} mt={4}>
        <Typography variant="body2" mt={2}>
          A temporary example showing link to details page
        </Typography>
        <Link
          href="/appointments/appointmentId"
          sx={{
            textDecoration: 'underline',
            fontWeight: 'bold',
            fontSize: 'inherit'
          }}
          variant="body2"
        >
          Details Page
        </Link>
      </Box>
    </BoxWithGladlySpace>
  )
}

export default AppointmentsList
