import { CustomerBookFilterKeys } from 'types/CustomerBookFilters'
import {
  NR_CUSTOMER_BOOK_FILTERS,
  NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS,
  NR_CUSTOMER_BOOK_SORTS,
  NR_CUSTOMER_BOOK_SORT_SELECTION_EVENTS
} from 'constants/clienteling/newRelicEvents/nrCustomerBookPage'

const {
  ANNIVERSARY,
  NORDY_CLUB,
  CARDMEMBER,
  NORDSTROM_NOTES,
  TRIPS_COUNT,
  LAST_PURCHASE,
  LAST_MESSAGE_TIME,
  SAVED_BRANDS
} = CustomerBookFilterKeys

const {
  ANNIVERSARY_FILTER,
  NORDY_CLUB_FILTER,
  CARDMEMBER_FILTER,
  NORDSTROM_NOTES_FILTER,
  TRIPS_COUNT_FILTER,
  LAST_PURCHASE_DATE_FILTER,
  LAST_MESSAGE_SENT_FILTER,
  BRANDS_FILTER
} = NR_CUSTOMER_BOOK_FILTERS

const {
  ANNIVERSARY_FILTER: ANNIVERSARY_FILTER_SELECTION,
  NORDY_CLUB_FILTER: NORDY_CLUB_FILTER_SELECTION,
  CARDMEMBER_FILTER: CARDMEMBER_FILTER_SELECTION,
  NORDSTROM_NOTES_FILTER: NORDSTROM_NOTES_FILTER_SELECTION,
  TRIPS_COUNT_FILTER: TRIPS_COUNT_FILTER_SELECTION,
  LAST_PURCHASE_DATE_FILTER: LAST_PURCHASE_DATE_FILTER_SELECTION,
  LAST_MESSAGE_SENT_FILTER: LAST_MESSAGE_SENT_FILTER_SELECTION,
  BRANDS_FILTER: BRANDS_FILTER_SELECTION
} = NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS

export const MAP_FILTER_INFO_TO_NR_EVENT = {
  [ANNIVERSARY]: {
    filterType: ANNIVERSARY_FILTER,
    selectionType: ANNIVERSARY_FILTER_SELECTION
  },
  [NORDY_CLUB]: {
    filterType: NORDY_CLUB_FILTER,
    selectionType: NORDY_CLUB_FILTER_SELECTION
  },
  [CARDMEMBER]: {
    filterType: CARDMEMBER_FILTER,
    selectionType: CARDMEMBER_FILTER_SELECTION
  },
  [NORDSTROM_NOTES]: {
    filterType: NORDSTROM_NOTES_FILTER,
    selectionType: NORDSTROM_NOTES_FILTER_SELECTION
  },
  [TRIPS_COUNT]: {
    filterType: TRIPS_COUNT_FILTER,
    selectionType: TRIPS_COUNT_FILTER_SELECTION
  },
  [LAST_PURCHASE]: {
    filterType: LAST_PURCHASE_DATE_FILTER,
    selectionType: LAST_PURCHASE_DATE_FILTER_SELECTION
  },
  [LAST_MESSAGE_TIME]: {
    filterType: LAST_MESSAGE_SENT_FILTER,
    selectionType: LAST_MESSAGE_SENT_FILTER_SELECTION
  },
  [SAVED_BRANDS]: {
    filterType: BRANDS_FILTER,
    selectionType: BRANDS_FILTER_SELECTION
  }
} as const

export const {
  ALPHABETICAL_SORT,
  TRIPS_COUNT_SORT,
  LAST_MESSAGE_SENT_SORT,
  LAST_PURCHASE_DATE_SORT
} = NR_CUSTOMER_BOOK_SORTS

const {
  ALPHABETICAL_SORT: ALPHABETICAL_SORT_SELECTION,
  TRIPS_COUNT_SORT: TRIPS_COUNT_SORT_SELECTION,
  LAST_MESSAGE_SENT_SORT: LAST_MESSAGE_SENT_SORT_SELECTION,
  LAST_PURCHASE_DATE_SORT: LAST_PURCHASE_DATE_SORT_SELECTION
} = NR_CUSTOMER_BOOK_SORT_SELECTION_EVENTS

export const MAP_SORT_INFO_TO_NR_EVENT = {
  [ALPHABETICAL_SORT]: ALPHABETICAL_SORT_SELECTION,
  [TRIPS_COUNT_SORT]: TRIPS_COUNT_SORT_SELECTION,
  [LAST_MESSAGE_SENT_SORT]: LAST_MESSAGE_SENT_SORT_SELECTION,
  [LAST_PURCHASE_DATE_SORT]: LAST_PURCHASE_DATE_SORT_SELECTION
} as const
