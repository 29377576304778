import LoadingSpinner from 'components/LoadingSpinner'
import { useGetPolarisSearchResultsQuery } from 'services/employeeExperienceApi'
import { RecommendationDetailsT } from 'types/SeedItemRecommendations'
import PageError from 'components/PageError'
import ProductImageCarousel from 'components/ProductImageCarousel'
import { ProductGenderValues } from 'types/Checkout'
import { RECS_ERROR_TITLE } from './ItemRecommendations'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isFeatureEnabledForUser } from 'utils/userPermissions'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'

interface PropsT {
  recommendation: RecommendationDetailsT
  onClickForMiniPDP: (webStyleId: string) => void
  onClickForPDP: (webStyleId: string) => void
  curationId: number
  productGender?: ProductGenderValues
  searchTerm: string
}

const CategoryCarousel = ({
  recommendation,
  onClickForMiniPDP,
  onClickForPDP,
  curationId,
  productGender,
  searchTerm
}: PropsT): JSX.Element => {
  const { isMiniPDPForItemRecsEnabled } = useFeatureFlags()
  const accessGroupsPayload =
    isMiniPDPForItemRecsEnabled?.payload as AccessGroupsFeatureFlagT
  const miniPDPEnabled = isFeatureEnabledForUser(accessGroupsPayload)

  const { data, endpointName, error, isLoading, isFetching, isError, refetch } =
    useGetPolarisSearchResultsQuery({
      curationId,
      searchTerm,
      minPrice: recommendation.priceRange?.min,
      maxPrice: recommendation.priceRange?.max,
      productGender
    })

  if (isError) {
    return (
      <PageError
        errorDetails={{ endpoint: endpointName, errorData: error }}
        errorTitle={RECS_ERROR_TITLE}
        refreshAction={refetch}
      />
    )
  }

  if (!data || isLoading || isFetching) {
    return <LoadingSpinner hideText={true} />
  }

  return (
    <ProductImageCarousel
      data={data}
      miniPDPEnabled={miniPDPEnabled}
      onClickForMiniPDP={onClickForMiniPDP}
      onClickForPDP={onClickForPDP}
    />
  )
}

export default CategoryCarousel
