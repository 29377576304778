import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useContext
} from 'react'
import { Grid } from '@mui/material'
import NoRecentItems from '../NoRecentItems'
import PageError from 'components/PageError'
import RecentItemCard from '../RecentItemCard'
import RecentItemsLoading from '../RecentItemsLoading'
import AddItemErrorSnackbar from 'components/AddItemErrorSnackbar'
import {
  useAddItemToBagMutation,
  useGetRecentProductsQuery as useGetRecentItemsQuery
} from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import { EmployeeProductT } from 'types/Product'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { UPDATE_RECENT_ITEM_ADDS } from 'pages/CurationReview/constants'

export type PropsT = {
  handleAddItem: () => void
  handleClickForPdp: (webStyleId: string) => void
  handleClickForMiniPDP: (webStyleId: string) => void
  shopperId: string
  token: string
  refetchToken: () => void
  isEdit: boolean | null
}

const RecentItems = (props: PropsT): ReactElement => {
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)
  const {
    handleAddItem,
    handleClickForPdp,
    handleClickForMiniPDP,
    refetchToken,
    shopperId,
    token,
    isEdit
  } = props
  const [loadingItemId, setLoadingItemId] = useState<string | undefined>(
    undefined
  )
  const [addedItems, setAddedItems] = useState<string[]>([])

  const [addedItemUnavailable, setAddedItemUnavailable] = useState(false)
  const [checkoutTokenExpired, setCheckoutTokenExpired] = useState(false)
  const [retryNeeded, setRetryNeeded] = useState(false)
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const {
    data: recentItemsData,
    endpointName: recentItemsEndpoint,
    error: recentItemsError,
    isError: isGetRecentItemsError,
    isLoading: isGetRecentItemsLoading
  } = useGetRecentItemsQuery(employeeId)

  const curationDetails = useAppSelector(curationSelector)

  const newRelicAttributes = {
    curationId: curationDetails?.id,
    type: curationDetails?.type,
    preferredEmployeeId: curationDetails?.preferredEmployee,
    ocpId: curationDetails?.ocpId
  }

  const [
    addItemToBag,
    {
      error: addItemError,
      isError: isAddItemError,
      isLoading: isAddItemLoading
    }
  ] = useAddItemToBagMutation()

  const handleAddToBag = useCallback(
    (rmsSku: string) => {
      addItemToBag({
        shopperId,
        token,
        rmsSku
      })
        .unwrap()
        .then(() => {
          !isEdit &&
            dispatch &&
            dispatch({
              type: UPDATE_RECENT_ITEM_ADDS,
              payload: { rmsSku }
            })
          isEdit &&
            editDispatch &&
            editDispatch({
              type: UPDATE_RECENT_ITEM_ADDS,
              payload: { rmsSku }
            })
          setRetryNeeded(false)
          setAddedItems([...addedItems, rmsSku])
          handleAddItem()
          return
        })
        .catch(() => null)
    },
    [
      addItemToBag,
      handleAddItem,
      shopperId,
      addedItems,
      token,
      dispatch,
      editDispatch,
      isEdit
    ]
  )

  const onAddToBag = (rmsSku: string) => {
    setRetryNeeded(true)
    setLoadingItemId(rmsSku)
    handleAddToBag(rmsSku)
    generateNewRelicLogs('itemAddedFromRecentItems', {
      ...newRelicAttributes,
      rmsSku
    })
  }

  const refetchTokenAndRetry = useCallback(() => {
    refetchToken()
    setRetryNeeded(false)
    loadingItemId && handleAddToBag(loadingItemId)
  }, [handleAddToBag, loadingItemId, refetchToken])

  useEffect(() => {
    if (addItemError) {
      if ('status' in addItemError) {
        const errorMessage = JSON.stringify(addItemError.data)
        const itemUnavailable = errorMessage?.includes(
          'One or more items is not purchasable online'
        )
        setAddedItemUnavailable(itemUnavailable)
        if (retryNeeded) {
          setRetryNeeded(false)
          const tokenExpired = errorMessage?.includes('Invalid token')
          setCheckoutTokenExpired(tokenExpired)
        }
      }
    }
  }, [addItemError, retryNeeded])

  useEffect(() => {
    if (checkoutTokenExpired) {
      refetchTokenAndRetry()
      setCheckoutTokenExpired(false)
    }
  }, [checkoutTokenExpired, refetchTokenAndRetry])

  if (isGetRecentItemsLoading) {
    return <RecentItemsLoading />
  }

  if (isGetRecentItemsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: recentItemsEndpoint,
          errorData: recentItemsError,
          identifiers: {
            employeeId
          }
        }}
      />
    )
  }

  if (recentItemsData && recentItemsData.length === 0) {
    return <NoRecentItems />
  }

  return (
    <Grid container>
      {recentItemsData?.map((item: EmployeeProductT) => {
        if (item.rmsSku || item.webStyleId) {
          return (
            <RecentItemCard
              key={item.rmsSku}
              brandName={item.brandName}
              clearancePrice={item.clearancePrice}
              color={item.color}
              currentPriceType={item.currentPriceType}
              enticements={item.enticements}
              imageUrl={item.imageUrl}
              isAddItemLoading={
                loadingItemId === item.rmsSku && isAddItemLoading
              }
              isAvailable={item.isAvailable || false}
              isItemAdded={addedItems.includes(item.rmsSku)}
              itemName={item.itemName}
              itemNumber={item.itemNumber}
              onClickAdd={onAddToBag}
              onClickForPDP={handleClickForPdp}
              onClickForMiniPDP={handleClickForMiniPDP}
              promotionPrice={item.promotionPrice}
              regularPrice={item.regularPrice}
              rmsSku={item.rmsSku}
              size={item.size}
              webStyleId={item.webStyleId}
            />
          )
        }
      })}
      {isAddItemError && (
        <AddItemErrorSnackbar itemUnavailable={addedItemUnavailable} />
      )}
    </Grid>
  )
}

export default RecentItems
