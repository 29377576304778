import { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import type { SelectChangeEvent } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { useGetProductByWebStyleIdQuery } from 'services/employeeExperienceApi'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import DrawerNavigation from 'components/DrawerNavigation'
import ItemImage from 'components/ItemImage'
import LoadingSpinner from 'components/LoadingSpinner'
import ProductPrice from 'components/ProductPrice'
import { useAddItemToBagMutation } from 'services/curationSvc'
import AddItemErrorSnackbar from 'components/AddItemErrorSnackbar'
import { ValidationStatus } from 'types/FormValidation'
import { LoadingButton } from '@mui/lab'

export type PropsT = {
  handleClickForPdp: (webstyleId: string) => void
  syncItems: () => void
  onClose: () => void
  webStyleId: string
  isMiniPDPOpen: boolean
  shopperId: string
  token: string
  refetchToken: () => void
  isPublicBoard: boolean
}

const MiniPDPDrawer = (props: PropsT): ReactElement => {
  const {
    handleClickForPdp,
    syncItems,
    onClose,
    webStyleId,
    isMiniPDPOpen,
    shopperId,
    token,
    refetchToken,
    isPublicBoard
  } = props

  const [selectedSize, setSelectedSize] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedSku, setSelectedSku] = useState('')
  const [loadingItemId, setLoadingItemId] = useState<string | undefined>(
    undefined
  )
  const [addedItemUnavailable, setAddedItemUnavailable] = useState(false)
  const [checkoutTokenExpired, setCheckoutTokenExpired] = useState(false)
  const [retryNeeded, setRetryNeeded] = useState(false)
  const [sizeRequiredError, setSizeRequiredError] = useState('')

  const { data: productData, isLoading: isGetProductByWebStyleIdLoading } =
    // use '6939169' for testing
    useGetProductByWebStyleIdQuery(webStyleId, { skip: !webStyleId })

  const [
    addItemToBag,
    {
      error: addItemError,
      isError: isAddItemError,
      isLoading: isAddItemLoading
    }
  ] = useAddItemToBagMutation()

  const validateForm = useCallback(() => {
    let validationStatus = ValidationStatus.PASS

    if (!isPublicBoard && !selectedSize) {
      setSizeRequiredError('Select a size to add to board')
    }
    if (selectedSize || isPublicBoard) {
      setSizeRequiredError('')
    }
    if (sizeRequiredError !== '') {
      validationStatus = ValidationStatus.FAIL
    }
    return {
      validationStatus
    }
  }, [isPublicBoard, selectedSize, sizeRequiredError])

  const handleAddToBag = useCallback(
    (rmsSku: string) => {
      const validationResult = validateForm()
      if (validationResult.validationStatus === ValidationStatus.FAIL) {
        return
      }

      if (validationResult.validationStatus === ValidationStatus.PASS) {
        addItemToBag({
          shopperId,
          token,
          rmsSku
        })
          .unwrap()
          .then(() => {
            setRetryNeeded(false)
            syncItems()
            onClose()
            return
          })
          .catch(() => null)
      }
    },
    [addItemToBag, syncItems, shopperId, token, validateForm, onClose]
  )

  const onAddToBag = (rmsSku: string) => {
    setRetryNeeded(true)
    setLoadingItemId(rmsSku)
    handleAddToBag(rmsSku)
  }

  const refetchTokenAndRetry = useCallback(() => {
    refetchToken()
    setRetryNeeded(false)
    loadingItemId && handleAddToBag(loadingItemId)
  }, [handleAddToBag, loadingItemId, refetchToken])

  useEffect(() => {
    if (addItemError) {
      if ('status' in addItemError) {
        const errorMessage = JSON.stringify(addItemError.data)
        const itemUnavailable = errorMessage?.includes(
          'One or more items is not purchasable online'
        )
        setAddedItemUnavailable(itemUnavailable)
        if (retryNeeded) {
          setRetryNeeded(false)
          const tokenExpired = errorMessage?.includes('Invalid token')
          setCheckoutTokenExpired(tokenExpired)
        }
      }
    }
  }, [addItemError, retryNeeded])

  useEffect(() => {
    if (selectedSize && sizeRequiredError) {
      setSizeRequiredError('')
    }
  }, [selectedSize, sizeRequiredError])

  useEffect(() => {
    if (checkoutTokenExpired) {
      refetchTokenAndRetry()
      setCheckoutTokenExpired(false)
    }
  }, [checkoutTokenExpired, refetchTokenAndRetry])

  useEffect(() => {
    if (isPublicBoard && selectedColor) {
      const sku =
        productData &&
        productData.skus.find((sku) => sku.color === selectedColor)
      if (sku) {
        sku.rmsSku && setSelectedSku(sku.rmsSku)
      }
    } else if (selectedSize && selectedColor) {
      const sku =
        productData &&
        productData.skus.find(
          (sku) => sku.size === selectedSize && sku.color === selectedColor
        )
      if (sku) {
        sku.rmsSku && setSelectedSku(sku.rmsSku)
      }
    }
  }, [selectedSize, selectedColor, productData, isPublicBoard])

  const handleSizeChange = (event: SelectChangeEvent<string>) => {
    setSelectedSize(event.target.value)
    setSizeRequiredError('')
  }

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    setSelectedColor(event.target.value)
  }

  if (isGetProductByWebStyleIdLoading) {
    return <LoadingSpinner />
  }

  const sizes = productData?.skus?.filter(
    (sku, index, skus) =>
      index === skus.findIndex((x) => x.size === sku.size && sku.size !== '')
  )

  const colors = productData?.skus?.filter(
    (sku, index, skus) =>
      index === skus.findIndex((x) => x.color === sku.color && sku.color !== '')
  )

  return (
    <Drawer open={isMiniPDPOpen} onClose={onClose}>
      {productData && (
        <BoxWithGladlySpace sx={{ px: 2 }}>
          <DrawerNavigation
            headerEndButton={null}
            onClick={onClose}
            title="Quick view"
            showBackButton={false}
          />
          <Box display="flex" flexDirection="column">
            <ItemImage
              imageUrl={productData.imageUrl}
              imageAltText={productData.brandName}
              width={200}
            />
            <Typography variant="subtitle2">{productData.brandName}</Typography>
            <Typography variant="body2">{productData.productName}</Typography>
            {productData.price && (
              <ProductPrice
                stylePrice={productData.price}
                // skuPrice={selectedSku.price}
              />
            )}
            <Typography variant="caption" color="text.secondary" mb={1}>
              Item #{productData.itemNumber}
            </Typography>
            <Typography variant="caption">{productData.description}</Typography>
            {!isPublicBoard && (
              <FormControl fullWidth sx={{ mt: 2 }} error={!!sizeRequiredError}>
                <InputLabel id="size-select-label">Size</InputLabel>
                <Select
                  labelId="size-select-label"
                  value={selectedSize}
                  onChange={handleSizeChange}
                  label="Size"
                  sx={{
                    color: sizeRequiredError ? 'error' : 'default'
                  }}
                >
                  {sizes &&
                    sizes.map((sku) => {
                      return (
                        <MenuItem key={sku.size} value={sku.size}>
                          {sku.size}
                        </MenuItem>
                      )
                    })}
                </Select>
                <Typography variant="caption" color="red">
                  {sizeRequiredError}
                </Typography>
              </FormControl>
            )}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="color-select-label">Color</InputLabel>
              <Select
                labelId="color-select-label"
                value={selectedColor}
                onChange={handleColorChange}
                defaultValue={selectedColor}
                label="Color"
              >
                {colors &&
                  colors.map((sku) => {
                    return (
                      <MenuItem key={sku.color} value={sku.color}>
                        {sku.color}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            {isPublicBoard && sizes && (
              <Typography mt={2} variant="caption">
                Available Sizes:{' '}
                {sizes
                  .map((sku) => {
                    return sku.size
                  })
                  .join(', ')}
              </Typography>
            )}
            <Box display="flex" justifyContent="center" alignItems="center">
              <LoadingButton
                onClick={() => {
                  // use '6939169' for testing
                  onAddToBag(selectedSku)
                }}
                loading={isAddItemLoading}
                fullWidth
                sx={{ mt: 5, mb: 3 }}
                variant="contained"
              >
                Add to Board
              </LoadingButton>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={() => {
                  // use '6939169' for testing
                  handleClickForPdp(webStyleId)
                }}
                startIcon={<LaunchIcon />}
                sx={{
                  maxWidth: '162px'
                }}
              >
                View Full Details
              </Button>
            </Box>
          </Box>
        </BoxWithGladlySpace>
      )}
      {isAddItemError && (
        <AddItemErrorSnackbar itemUnavailable={addedItemUnavailable} />
      )}
    </Drawer>
  )
}

export default MiniPDPDrawer
