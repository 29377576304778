import mapValues from 'lodash/mapValues'
import {
  ExtendedCustomerListByIdTransformedResponseT,
  ExtendedCustomerListResponseByIdT
} from 'types/ConsentedCustomer'
import {
  WishListResponse,
  TransformedWishListItemT,
  WishListItem,
  TransformedWishList
} from 'types/WishList'

export const getTransformedWishListResponse = (
  data: WishListResponse
): TransformedWishList => {
  const { defaultWishListId, wishList, wishLists } = data
  const wishListResponse = {
    id: wishList.wishListId,
    defaultList: defaultWishListId,
    wishLists: wishLists,
    items: wishList.items.map(
      ({
        displayImage,
        brandName = '',
        createdDate = '',
        rmsSkuId = '',
        name = '',
        enticements = [],
        originalStyleGroupNumber = '',
        styleId = undefined,
        skuPrice = undefined,
        isPublished = undefined,
        colorOption = undefined,
        size1Option = undefined,
        quantityPurchased = 0,
        quantityRequested = 0,
        quantity = 0
      }: WishListItem) =>
        ({
          imageUrl: displayImage?.url || '',
          brandName,
          addedAt: createdDate,
          rmsSkuId,
          name,
          enticements,
          originalStyleGroupNumber,
          styleId,
          price: skuPrice,
          isPublished,
          colorOption,
          size1Option,
          hasPurchased: !!quantityPurchased,
          quantityRequested,
          quantity
        } as TransformedWishListItemT)
    )
  }
  return wishListResponse
}

export const getTransformedExtendedCustomerListResponse = (
  extendedCustomerData: ExtendedCustomerListResponseByIdT
): ExtendedCustomerListByIdTransformedResponseT => {
  const tripsByCustomerId = mapValues(
    extendedCustomerData,
    (customer) => customer?.tripsCount ?? 0
  )
  const lastPurchaseDateByCustomerId = mapValues(
    extendedCustomerData,
    (customer) => customer?.lastPurchaseDate ?? ''
  )

  return {
    tripsCount: tripsByCustomerId,
    lastPurchaseDate: lastPurchaseDateByCustomerId
  }
}
