/* Types for In-App Notifications */

export enum Status {
  UNREAD = 'unread',
  READ = 'read',
  CLICKED = 'clicked'
}

export enum Type {
  NEW_CUSTOMER = 'customer_consented',
  CUSTOMER_ENDED = 'customer_unconsented',
  CUSTOMER_RENEWED = 'customer_reconsented',
  NEW_APPOINTMENT = 'new_appointment'
}

export type CustomerMetaData = {
  customerName: string
  customerId: string
  currentCustomerConsentType: Type
}

export type AppointmentMetaData = {
  appointmentId: string
  customerName: string
}

export type Notification = {
  id: string
  createdAt: string
  updatedAt: string
  occurredAt: string
  type: Type
  status: Status
  meta: CustomerMetaData | AppointmentMetaData
}

export type GetNotificationsResponse = {
  notifications: Notification[]
}

export type getUnreadNotificationsCountResponse = {
  totalUnreadCount: number
}

/* Types for Push Notifications */

export type SavedPushSubscription = {
  endpoint: string
  employeeId: string
  authKey: string
  p256dhKey: string
}

export type PushSubscription = {
  endpoint?: string
  keys?: {
    auth?: string
    p256dh?: string
  }
}
