import { ReactElement } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { visuallyHidden } from '@mui/utils'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import LaunchIcon from '@mui/icons-material/Launch'
import ItemDetails from 'components/ItemDetails'
import { EnticementDetailsT, PriceTypes } from 'types/Product'
import { determineCurrentPrice } from 'utils/priceHelpers'

export type PropsT = {
  brandName?: string
  clearancePrice?: number
  color?: string
  currentPriceType?: PriceTypes
  enticements?: EnticementDetailsT[]
  imageUrl?: string
  isAddItemLoading: boolean
  isAvailable: boolean
  isItemAdded: boolean
  itemName?: string
  itemNumber?: string
  onClickAdd: (rmsSku: string) => void
  onClickForPDP: (webStyleId: string) => void
  onClickForMiniPDP: (webStyleId: string) => void
  promotionPrice?: number
  regularPrice?: number
  rmsSku?: string
  size?: string
  webStyleId?: string
}

const RecentItemCard = (props: PropsT): ReactElement | null => {
  const {
    brandName,
    clearancePrice,
    color,
    currentPriceType,
    enticements,
    imageUrl,
    isAddItemLoading,
    isAvailable,
    isItemAdded,
    itemName,
    itemNumber,
    onClickAdd,
    onClickForPDP,
    onClickForMiniPDP,
    promotionPrice,
    regularPrice,
    rmsSku,
    size,
    webStyleId
  } = props

  if (!rmsSku && !webStyleId) {
    return null
  }

  const currentPrice = determineCurrentPrice({
    clearancePrice: clearancePrice,
    priceType: currentPriceType,
    promotionPrice: promotionPrice,
    regularPrice: regularPrice
  })

  return (
    <Grid container display="flex" alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={10} display="flex">
        <ItemDetails
          brandName={brandName}
          color={color}
          currentPrice={currentPrice}
          enticements={enticements}
          imageUrl={imageUrl}
          itemName={itemName}
          itemNumber={itemNumber}
          onClickForPDP={onClickForPDP}
          onClickForMiniPDP={onClickForMiniPDP}
          regularPrice={regularPrice}
          size={size}
          webStyleId={webStyleId}
        />
      </Grid>
      <Grid item display="flex" justifyContent="center" xs={2}>
        {isAvailable && rmsSku && (
          <LoadingButton
            disabled={!!isItemAdded}
            onClick={() => onClickAdd(rmsSku)}
            loading={isAddItemLoading}
            loadingPosition="center"
            startIcon={isItemAdded ? <CheckIcon /> : <AddIcon />}
          >
            {isItemAdded ? 'Added' : 'Add'}
          </LoadingButton>
        )}
        {!isAvailable && webStyleId && (
          <Button
            onClick={() => onClickForPDP(webStyleId)}
            startIcon={<LaunchIcon />}
          >
            View <Typography sx={visuallyHidden}>product details</Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default RecentItemCard
