import { Box, Button, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import AddIcon from '@mui/icons-material/Add'
import Carousel from 'components/Carousel'
import ItemImage from 'components/ItemImage'
import PriceRange from 'components/PriceRange'
import { PolarisSearchProductsT } from 'types/PolarisProduct'
import { checkBrowserClient } from 'utils/userAgentDetector'

interface PropsT {
  data: PolarisSearchProductsT
  miniPDPEnabled: boolean
  onClickForMiniPDP?: (webStyleId: string) => void
  onClickForPDP: (webStyleId: string) => void
}

const ProductImageCarousel = (props: PropsT): JSX.Element => {
  const { data, miniPDPEnabled, onClickForMiniPDP, onClickForPDP } = props
  const isMobile = checkBrowserClient.isMobile()

  return (
    <Carousel displayArrows={!isMobile} closeSpacing={true}>
      {data &&
        data.products &&
        data.products.map((item) => (
          <Box key={item.webStyleId} sx={{ width: '130px' }}>
            <Button
              aria-label="view product details"
              onClick={() => {
                if (onClickForMiniPDP && item?.webStyleId && miniPDPEnabled) {
                  onClickForMiniPDP(item.webStyleId.toString())
                } else if (item?.webStyleId) {
                  item?.webStyleId && onClickForPDP(item.webStyleId.toString())
                }
              }}
              sx={{ maxWidth: '120px', padding: 0 }}
              startIcon={
                miniPDPEnabled ? (
                  <AddIcon
                    sx={{
                      position: 'absolute',
                      top: '2%',
                      right: '2%',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      padding: '5px',
                      width: '1.5em',
                      height: 'auto',
                      opacity: '0.95'
                    }}
                  />
                ) : (
                  <LaunchIcon
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '-2px',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      padding: '5px',
                      width: '1.5em',
                      height: 'auto',
                      opacity: '0.85'
                    }}
                  />
                )
              }
            >
              <ItemImage imageUrl={item.imgUrl} height={184} width={120} />
            </Button>
            <Typography
              variant={'body2'}
              my={0.5}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                maxWidth: '90px'
              }}
            >
              {item.brandName}
            </Typography>
            <PriceRange price={item.price} />
          </Box>
        ))}
    </Carousel>
  )
}

export default ProductImageCarousel
