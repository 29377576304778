import { ReactElement } from 'react'
import { Typography, Box } from '@mui/material'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'

const AppointmentDetails = (): ReactElement => {
  return (
    <BoxWithGladlySpace>
      <Box px={2} mt={4}>
        <Typography variant="h6">Appointments Details</Typography>
      </Box>
    </BoxWithGladlySpace>
  )
}

export default AppointmentDetails
