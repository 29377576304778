import partition from 'lodash/partition'
import { ExtendedConsentedCustomerListItemT } from 'types/ConsentedCustomer'

export const CUSTOMER_SORT_OPTIONS = {
  BY_ALPHABETICAL: 'byAlphabetical',
  BY_OLDEST_MESSAGES: 'byOldestMessages',
  BY_NEWEST_MESSAGES: 'byNewestMessages',
  BY_MOST_TRIPS: 'byMostTrips',
  BY_FEWEST_TRIPS: 'byFewestTrips',
  BY_MOST_RECENT_PURCHASE: 'byMostRecentPurchase',
  BY_LEAST_RECENT_PURCHASE: 'byLeastRecentPurchase'
} as const

export type CustomerSortKeysT =
  typeof CUSTOMER_SORT_OPTIONS[keyof typeof CUSTOMER_SORT_OPTIONS]

const byAlphabetical = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  customers.sort((a, b) => {
    let aSortString = `${a.firstName} ${a.lastName}`.toLowerCase()
    let bSortString = `${b.firstName} ${b.lastName}`.toLowerCase()

    if (!a.firstName && !a.lastName) {
      aSortString = a.email
    }

    if (!b.firstName && !b.lastName) {
      bSortString = b.email
    }

    return aSortString < bSortString ? -1 : 1
  })

const sortCustomersByMessageDate = (
  customers: ExtendedConsentedCustomerListItemT[],
  comparator: (customerA: Date, customerB: Date) => number
) => {
  const [customersWithMessages, customersWithoutMessages] = partition(
    customers,
    (customer) => !!customer.lastMessage
  )

  const customersWithoutMessagesSortedByAlpha = byAlphabetical(
    customersWithoutMessages
  )

  const customersWithPurchasesSortedByPurchases = customersWithMessages.sort(
    (customerA, customerB) => {
      const messageDateA = new Date(customerA.lastMessage as string)
      const messageDateB = new Date(customerB.lastMessage as string)
      return comparator(messageDateA, messageDateB)
    }
  )

  return [
    ...customersWithPurchasesSortedByPurchases,
    ...customersWithoutMessagesSortedByAlpha
  ]
}

const byNewestMessages = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => {
  return sortCustomersByMessageDate(
    customers,
    (customerAMessageDate, customerBMessageDate) =>
      customerBMessageDate.getTime() - customerAMessageDate.getTime()
  )
}

const byOldestMessages = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => {
  return sortCustomersByMessageDate(
    customers,
    (customerAMessageDate, customerBMessageDate) =>
      customerAMessageDate.getTime() - customerBMessageDate.getTime()
  )
}

const sortCustomersByTrips = (
  customers: ExtendedConsentedCustomerListItemT[],
  comparator: (customerA: number, customerB: number) => number,
  fewestFirst: boolean
) => {
  const [customersWithTrips, customersWithoutTrips] = partition(
    customers,
    (customer) => !!customer.tripsCount
  )

  const customersWithTripsSortedByAlpha = byAlphabetical(customersWithTrips)
  const customersWithoutTripsSortedByAlpha = byAlphabetical(
    customersWithoutTrips
  )

  const customersWithTripsSortedByTrips = customersWithTripsSortedByAlpha.sort(
    (a, b) => {
      const tripsCountA = a.tripsCount as number
      const tripsCountB = b.tripsCount as number
      return comparator(tripsCountA, tripsCountB)
    }
  )

  return fewestFirst
    ? [
        ...customersWithoutTripsSortedByAlpha,
        ...customersWithTripsSortedByTrips
      ]
    : [
        ...customersWithTripsSortedByTrips,
        ...customersWithoutTripsSortedByAlpha
      ]
}

const byMostTrips = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => {
  return sortCustomersByTrips(
    customers,
    (customerATrips, customerBTrips) => customerBTrips - customerATrips,
    false
  )
}

const byFewestTrips = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => {
  return sortCustomersByTrips(
    customers,
    (customerATrips, customerBTrips) => customerATrips - customerBTrips,
    true
  )
}

const sortCustomersByPurchaseDate = (
  customers: ExtendedConsentedCustomerListItemT[],
  comparator: (customerA: Date, customerB: Date) => number
) => {
  const [customersWithPurchases, customersWithoutPurchases] = partition(
    customers,
    (customer) => !!customer.lastPurchaseDate
  )

  const customersWithoutPurchasesSortedByAlpha = byAlphabetical(
    customersWithoutPurchases
  )

  const customersWithPurchasesSortedByAlpha = byAlphabetical(
    customersWithPurchases
  )

  const customersWithPurchasesSortedByPurchases =
    customersWithPurchasesSortedByAlpha.sort((customerA, customerB) => {
      const purchaseDateA = new Date(customerA.lastPurchaseDate as string)
      const purchaseDateB = new Date(customerB.lastPurchaseDate as string)
      return comparator(purchaseDateA, purchaseDateB)
    })

  return [
    ...customersWithPurchasesSortedByPurchases,
    ...customersWithoutPurchasesSortedByAlpha
  ]
}

const byMostRecentPurchase = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => {
  return sortCustomersByPurchaseDate(
    customers,
    (customerA, customerB) => customerB.getTime() - customerA.getTime()
  )
}

const byLeastRecentPurchase = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => {
  return sortCustomersByPurchaseDate(
    customers,
    (customerA, customerB) => customerA.getTime() - customerB.getTime()
  )
}

export const CUSTOMER_SORT_FUNCTIONS = {
  byAlphabetical,
  byOldestMessages,
  byNewestMessages,
  byMostTrips,
  byFewestTrips,
  byMostRecentPurchase,
  byLeastRecentPurchase
}
