import { ReactElement } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { formatPrice } from 'utils/formatPrice'
import { SalesMetricT } from 'types/SalesApi'

const Yesterday = 'yesterday'
const ThisWeek = 'thisWeek'
const CurrentPayPeriod = 'currentPayPeriod'
const YearToDate = 'yearToDate'

type PropsT = {
  salesData: SalesMetricT
  channel: string
}

const Store = 'store'
const Styleboard = 'styleboard'

const StoreAndStyleboardSales = (props: PropsT): ReactElement => {
  const { salesData, channel } = props
  const { search } = useLocation()
  const history = useHistory()

  const query = new URLSearchParams(search)
  const timeframe = query.get('timeframe') || ThisWeek

  const handleTimeframeSelect = (value: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('timeframe', value)
    history.replace({ search: searchParams.toString() })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const salesMetricMap: any = {
    [YearToDate]: {
      [Store]: {
        net: {
          thisYear: salesData.store_net_amt_y2d,
          lastYear: salesData.store_net_amt_y2d_ly
        },
        gross: {
          thisYear: salesData.store_gross_amt_y2d,
          lastYear: salesData.store_gross_amt_y2d_ly
        }
      },
      [Styleboard]: {
        net: {
          thisYear: salesData.digital_net_amt_y2d,
          lastYear: salesData.digital_net_amt_y2d_ly
        },
        gross: {
          thisYear: salesData.digital_gross_amt_y2d,
          lastYear: salesData.digital_gross_amt_y2d_ly
        }
      }
    },
    [ThisWeek]: {
      [Store]: {
        net: {
          thisYear: salesData.store_net_amt_w2d,
          lastYear: salesData.store_net_amt_w2d_ly
        },
        gross: {
          thisYear: salesData.store_gross_amt_w2d,
          lastYear: salesData.store_gross_amt_w2d_ly
        }
      },
      [Styleboard]: {
        net: {
          thisYear: salesData.digital_net_amt_w2d,
          lastYear: salesData.digital_net_amt_w2d_ly
        },
        gross: {
          thisYear: salesData.digital_gross_amt_w2d,
          lastYear: salesData.digital_gross_amt_w2d_ly
        }
      }
    },
    [CurrentPayPeriod]: {
      [Store]: {
        net: {
          thisYear: salesData.store_net_amt_pp2d,
          lastYear: salesData.store_net_amt_pp2d_ly
        },
        gross: {
          thisYear: salesData.store_gross_amt_pp2d,
          lastYear: salesData.store_gross_amt_pp2d_ly
        }
      },
      [Styleboard]: {
        net: {
          thisYear: salesData.digital_net_amt_pp2d,
          lastYear: salesData.digital_net_amt_pp2d_ly
        },
        gross: {
          thisYear: salesData.digital_gross_amt_pp2d,
          lastYear: salesData.digital_gross_amt_pp2d_ly
        }
      }
    },
    [Yesterday]: {
      [Store]: {
        net: {
          thisYear: salesData.store_net_amt_ty,
          lastYear: salesData.store_net_amt_ly
        },
        gross: {
          thisYear: salesData.store_gross_amt_ty,
          lastYear: salesData.store_gross_amt_ly
        }
      },
      [Styleboard]: {
        net: {
          thisYear: salesData.digital_net_amt_ty,
          lastYear: salesData.digital_net_amt_ly
        },
        gross: {
          thisYear: salesData.digital_gross_amt_ty,
          lastYear: salesData.digital_gross_amt_ly
        }
      }
    }
  }

  return (
    <Container disableGutters>
      <FormControl fullWidth sx={{ maxWidth: 450, pb: 3 }}>
        <InputLabel>Timeframe</InputLabel>
        <Select
          defaultValue="current_pay_period"
          label="Timeframe"
          value={timeframe}
          onChange={(event) => handleTimeframeSelect(event.target.value)}
        >
          <MenuItem key={Yesterday} value={Yesterday}>
            Yesterday
          </MenuItem>
          <MenuItem key={ThisWeek} value={ThisWeek}>
            This week (Sun - Sat)
          </MenuItem>
          <MenuItem key={CurrentPayPeriod} value={CurrentPayPeriod}>
            Current pay period
          </MenuItem>
          <MenuItem key={YearToDate} value={YearToDate}>
            Year to date
          </MenuItem>
        </Select>
      </FormControl>
      <Typography variant="subtitle2">Net sales</Typography>
      <Typography sx={{ py: 1 }} variant="h5">
        {formatPrice.format(salesMetricMap[timeframe][channel].net.thisYear)}
      </Typography>
      <Typography color="secondary" variant="body2">
        Last year:{' '}
        {formatPrice.format(salesMetricMap[timeframe][channel].net.lastYear)}
      </Typography>
      <Typography sx={{ pt: 3 }} variant="subtitle2">
        Gross sales
      </Typography>
      <Typography sx={{ py: 1 }} variant="h5">
        {formatPrice.format(salesMetricMap[timeframe][channel].gross.thisYear)}
      </Typography>
      <Typography color="secondary" variant="body2">
        Last year:{' '}
        {formatPrice.format(salesMetricMap[timeframe][channel].gross.lastYear)}
      </Typography>
    </Container>
  )
}

export default StoreAndStyleboardSales
