import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import ProductImageCarousel from 'components/ProductImageCarousel'
import { useGetPolarisSearchResultsQuery } from 'services/employeeExperienceApi'
import { RECS_ERROR_TITLE } from './StartBoardRecommendations'

interface PropsT {
  onClickForPDP: (webStyleId: string) => void
  curationId: number
  searchTerm: string
  budgetRange: number
}

const CategoryCarousel = ({
  onClickForPDP,
  curationId,
  searchTerm,
  budgetRange
}: PropsT): JSX.Element => {
  const { data, endpointName, error, isLoading, isFetching, isError, refetch } =
    useGetPolarisSearchResultsQuery({
      curationId,
      searchTerm,
      minPrice: budgetRange <= 100 ? 0 : budgetRange / 2,
      maxPrice: budgetRange * 1.2
    })

  if (isError) {
    return (
      <PageError
        errorDetails={{ endpoint: endpointName, errorData: error }}
        errorTitle={RECS_ERROR_TITLE}
        refreshAction={refetch}
      />
    )
  }

  if (!data || isLoading || isFetching) {
    return <LoadingSpinner hideText={true} />
  }

  return (
    <ProductImageCarousel
      data={data}
      miniPDPEnabled={false}
      onClickForPDP={onClickForPDP}
    />
  )
}

export default CategoryCarousel
