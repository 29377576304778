import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, Typography, useTheme, Chip } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import { SetStateAction, useContext, useEffect, useState } from 'react'
import { useGenerateSeedItemRecommendationsMutation } from 'services/employeeExperienceApi'
import { CurationItemT, ItemRecommendationsT } from 'types/Curation'
import CategoryCarousel from './CategoryCarousel'
import { SET_SEED_ITEM_RECS_TERMS } from 'pages/CurationReview/constants'
import { CurationReviewDispatchContext } from '../CurationReviewContent/CurationReviewReducer'
import { emDashCharacter } from 'pages/constants'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

export const RECS_ERROR_TITLE = `Issue getting recommendations ${emDashCharacter}`

const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

interface SelectableChipT {
  category: string
  handleSearchTermChipClick: (
    searchTerm: string,
    index: number,
    recCategory: string
  ) => void
  searchTerm: string
  searchTermIndex: number
  selectedChipIndex: number
}

const SelectableChip = ({
  category,
  handleSearchTermChipClick,
  searchTerm,
  searchTermIndex,
  selectedChipIndex
}: SelectableChipT) => {
  return (
    <Chip
      size="small"
      label={searchTerm}
      onClick={() =>
        handleSearchTermChipClick(searchTerm, searchTermIndex, category)
      }
      sx={{
        backgroundColor:
          selectedChipIndex === searchTermIndex ? 'primary.main' : 'default',
        color: selectedChipIndex === searchTermIndex ? 'white' : 'black',
        '&:hover': {
          backgroundColor:
            selectedChipIndex === searchTermIndex ? 'primary.main' : 'default',
          color: selectedChipIndex === searchTermIndex ? 'white' : 'black'
        }
      }}
    />
  )
}

interface PropsT {
  curationId: number
  onClickForMiniPDP: (webStyleId: string) => void
  onClickForPDP: (webStyleId: string) => void
  seedItem?: CurationItemT
  storedRecommendations?: ItemRecommendationsT
}

const ItemRecommendations = ({
  curationId,
  onClickForMiniPDP,
  onClickForPDP,
  seedItem,
  storedRecommendations
}: PropsT): JSX.Element => {
  const theme = useTheme()
  const [tabPosition, setTabPosition] = useState('0')
  const [
    generateCategoriesAndTerms,
    { isLoading, error, endpointName, isError }
  ] = useGenerateSeedItemRecommendationsMutation()
  const dispatch = useContext(CurationReviewDispatchContext)
  const { seedItemWebStyleId: storedWebStyleId, itemRecommendationTerms } =
    storedRecommendations || {}

  const [selectedChipIndex, setSelectedChipIndex] = useState(0)

  const handleSearchTermChipClick = (
    searchTerm: string,
    index: number,
    recCategory: string
  ) => {
    setSelectedChipIndex(index)
    generateNewRelicLogs('itemRecsSearchTermChipClicked', {
      searchTerm,
      curationId,
      seedItemImageUrl: seedItem?.imageUrl,
      seedItemWebStyleId: seedItem?.webStyleId,
      recommendationCategory: recCategory
    })
  }

  const handleTabChange = (value: SetStateAction<string>) => {
    setTabPosition(value)
    setSelectedChipIndex(0)
  }

  useEffect(() => {
    const areRecsStored =
      itemRecommendationTerms &&
      itemRecommendationTerms?.recommendations?.length > 0
    const isSeedItemUpdated =
      seedItem && seedItem.webStyleId !== storedWebStyleId

    if (seedItem && (!areRecsStored || isSeedItemUpdated)) {
      generateCategoriesAndTerms({
        curationId,
        seedItemData: seedItem
      })
        .unwrap()
        .then((response) => {
          dispatch &&
            dispatch({
              type: SET_SEED_ITEM_RECS_TERMS,
              payload: {
                itemRecommendationTerms: response,
                webStyleId: seedItem.webStyleId
              }
            })
          return
        })
        .catch(() => null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curationId, seedItem, generateCategoriesAndTerms])

  useEffect(() => {
    if (tabPosition !== '0') setTabPosition('0')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedItem])

  if (!seedItem) {
    return <></>
  }

  return (
    <Box p={2} mb={3} sx={{ backgroundColor: theme.palette.grey[50] }}>
      <Typography variant="subtitle2" fontWeight={'bold'}>
        Complete the look
      </Typography>
      <Typography variant="subtitle2" fontWeight={'regular'}>
        Add items to round out this look
      </Typography>
      {isError && (
        <PageError
          errorTitle={RECS_ERROR_TITLE}
          errorDetails={{ endpoint: endpointName, errorData: error }}
          refreshAction={() =>
            generateCategoriesAndTerms({ curationId, seedItemData: seedItem })
          }
        />
      )}
      {isLoading ? (
        <LoadingSpinner hideText={true} />
      ) : (
        itemRecommendationTerms?.recommendations && (
          <TabContext value={tabPosition}>
            <TabList
              onChange={(_e, value) => handleTabChange(value)}
              variant="scrollable"
              sx={{ mb: 2 }}
            >
              {itemRecommendationTerms.recommendations.map((rec, index) => (
                <Tab
                  key={index}
                  value={index.toString()}
                  label={capitalize(rec.category)}
                />
              ))}
            </TabList>
            {itemRecommendationTerms.recommendations.map((rec, index) => (
              <TabPanel key={index} value={index.toString()} sx={{ p: 0 }}>
                <Box display="flex" flexWrap="wrap" gap={1.5} mb={2}>
                  {rec.searchTerms.map((searchTerm, searchTermIndex) => (
                    <SelectableChip
                      key={searchTermIndex}
                      searchTerm={searchTerm}
                      searchTermIndex={searchTermIndex}
                      selectedChipIndex={selectedChipIndex}
                      category={rec.category}
                      handleSearchTermChipClick={handleSearchTermChipClick}
                    />
                  ))}
                </Box>
                <CategoryCarousel
                  recommendation={rec}
                  onClickForMiniPDP={onClickForMiniPDP}
                  onClickForPDP={onClickForPDP}
                  curationId={curationId}
                  productGender={seedItem.productGender}
                  searchTerm={rec.searchTerms[selectedChipIndex]}
                />
              </TabPanel>
            ))}
          </TabContext>
        )
      )}
    </Box>
  )
}

export default ItemRecommendations
