import {
  NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS,
  NR_CUSTOMER_BOOK_FILTERS,
  NR_CUSTOMER_BOOK_SORT_SELECTION_EVENTS,
  NR_CUSTOMER_BOOK_SORTS
} from 'constants/clienteling/newRelicEvents/nrCustomerBookPage'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import { NewRelicStylingAttributesT } from 'types/NewRelicAttributes'
import {
  ALPHABETICAL_SORT,
  LAST_MESSAGE_SENT_SORT,
  MAP_FILTER_INFO_TO_NR_EVENT,
  MAP_SORT_INFO_TO_NR_EVENT,
  LAST_PURCHASE_DATE_SORT,
  TRIPS_COUNT_SORT
} from '../constants'
import { CUSTOMER_SORT_OPTIONS, CustomerSortKeysT } from './sortHelpers'
import {
  CustomerBookFilterKeys,
  CustomerBookFilters
} from 'types/CustomerBookFilters'

const isAlphabeticalSort = (selectedSortType: CustomerSortKeysT): boolean =>
  selectedSortType === 'byAlphabetical'

export const isLastMessageSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(
    selectedSortType === 'byNewestMessages' ||
    selectedSortType === 'byOldestMessages'
  )
export const isTripsCountSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(selectedSortType === 'byMostTrips' || selectedSortType === 'byFewestTrips')

export const isPurchasedDateSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE ||
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE
  )

const getNRSortType = (selectedSortType: CustomerSortKeysT) => {
  if (isAlphabeticalSort(selectedSortType)) {
    return ALPHABETICAL_SORT
  } else if (isLastMessageSort(selectedSortType)) {
    return LAST_MESSAGE_SENT_SORT
  } else if (isTripsCountSort(selectedSortType)) {
    return TRIPS_COUNT_SORT
  } else if (isPurchasedDateSort(selectedSortType)) {
    return LAST_PURCHASE_DATE_SORT
  }
}

const getNRSortSelectionType = (
  sortType: typeof NR_CUSTOMER_BOOK_SORTS[keyof typeof NR_CUSTOMER_BOOK_SORTS]
) => MAP_SORT_INFO_TO_NR_EVENT[sortType]

export const logAppliedSortNREvents = (
  selectedSortType: CustomerSortKeysT,
  generateNewRelicLogs: (
    nrEvent:
      | typeof NR_CUSTOMER_BOOK_SORTS[keyof typeof NR_CUSTOMER_BOOK_SORTS]
      | typeof NR_CUSTOMER_BOOK_SORT_SELECTION_EVENTS[keyof typeof NR_CUSTOMER_BOOK_SORT_SELECTION_EVENTS],
    nrEventAttribute: NewRelicStylingAttributesT
  ) => void
): void => {
  const sortType = getNRSortType(selectedSortType)

  const nrEventAttributes = {
    page: NR_CLIENTELING_PAGES.CUSTOMER_BOOK_PAGE,
    customerSortType: sortType
  }
  if (sortType) {
    generateNewRelicLogs(sortType, nrEventAttributes)

    const sortSelectionType = getNRSortSelectionType(sortType)

    generateNewRelicLogs(sortSelectionType, {
      ...nrEventAttributes,
      customerSortSelection: selectedSortType
    })
  }
}

export const logAppliedFilterNREvents = ({
  customerBookFilters,
  generateNewRelicLogs
}: {
  customerBookFilters: CustomerBookFilters
  generateNewRelicLogs: (
    nrEvent:
      | typeof NR_CUSTOMER_BOOK_FILTERS[keyof typeof NR_CUSTOMER_BOOK_FILTERS]
      | typeof NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS[keyof typeof NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS],
    nrEventAttribute: NewRelicStylingAttributesT
  ) => void
}): void[] =>
  (Object.keys(customerBookFilters) as CustomerBookFilterKeys[]).map(
    (filterKey) => {
      const checkedOptions = customerBookFilters[filterKey].options.filter(
        (option) => option.checked
      )

      if (checkedOptions.length > 0) {
        const filterType = MAP_FILTER_INFO_TO_NR_EVENT[filterKey].filterType
        const nrEventAttributes = {
          page: NR_CLIENTELING_PAGES.CUSTOMER_BOOK_PAGE,
          customerFilterType: filterType
        }
        const selectionType =
          MAP_FILTER_INFO_TO_NR_EVENT[filterKey].selectionType
        generateNewRelicLogs(filterType, nrEventAttributes)

        checkedOptions.map((option) => {
          generateNewRelicLogs(selectionType, {
            ...nrEventAttributes,
            customerFilterSelection: option.nameOverride ?? option.name
          })
        })
      }
    }
  )
