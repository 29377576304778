export type GridProductT = {
  name: string
  imageUrl: string
  id: string
  brandName: string
  styleId?: number
  quantity: number
}
export interface ProductT extends GridProductT {
  size: string
  color: string
  purchasePrice: number
  styleNumber: string
  style: number
  discount: number
  description: string
  quantity: number
  isSkuAvailable: boolean
}

export type EmployeeProductT = {
  isAvailable?: boolean
  brandName?: string
  clearancePrice?: number
  color?: string
  currentPriceType?: PriceTypes
  enticements?: EnticementDetailsT[]
  imageUrl?: string
  itemName?: string
  itemNumber?: string
  promotionPrice?: number
  regularPrice?: number
  rmsSku: string
  sizeLabel?: string
  size?: string
  webStyleId?: string
}

export type FavoriteProductT = {
  brandName?: string
  name?: string
  imageUrl?: string
  priceMax?: string
  priceMin?: string
  webStyleId?: string
}

export type ServerResponseT = { products: ServerEmployeeProductT[] }

export type FavoriteItemsResponseT = {
  favorites: ServerFavoriteItemsProductT[]
}

export type ServerEditItemsProductT = {
  ids: IdsT
  productAttributes: ProductAttributesT
  mediaExperiences: MediaExperiencesT
  sellingControls: SellingControlsT[]
  sku: ServerProductSkuT
}

export type EditItemsProductT = {
  brandName?: string
  productName?: string
  imageUrl?: string
  clearancePrice?: number
  promotionPrice?: number
  regularPrice?: number
  currentPriceType?: PriceTypes
  enticements?: EnticementDetailsT[]
  sizeLabel?: string
  size?: string
  color?: string
  rmsSku: string
  webStyleId?: string
}

export type ServerFavoriteItemsProductT = {
  ids: IdsT
  productAttributes: ProductAttributesT
  mediaExperiences: MediaExperiencesT
  sellingControls: SellingControlsT[]
  skus: ServerProductSkuT[]
}

export type ServerProductSkuT = {
  productAttributes: SkuProductAttributesT
  sellingControls: SellingControlsT[]
  ids: SkuIdsT
}

export type ServerEmployeeProductT = {
  ids: IdsT
  productAttributes: ProductAttributesT
  mediaExperiences: MediaExperiencesT
  sku: {
    productAttributes: SkuProductAttributesT
    sellingControls: SellingControlsT[]
    ids: SkuIdsT
  }
}

type IdsT = { webStyle: WebStyleT; rmsStyleGroup: ItemNumberT }

type WebStyleT = { id: string }

type ItemNumberT = { id: string }

type ProductAttributesT = {
  name: string
  vendor: BrandNameT
  sizeLabel: string
  description: string
}

type BrandNameT = { labelName: string }

type MediaExperiencesT = { carouselsByColor: CarouselT[] }

type CarouselT = { colorName: string; orderedShots: ImageUrlT[] }

type ImageUrlT = { url: string }

type SkuProductAttributesT = { size: SizeT }

type SizeT = { name: string }

type SellingControlsT = {
  marketingAttributes?: { enticements?: EnticementDetailsT[] }
  productAttributes: SellingControlsProductAttributesT
}

interface SkuSellingControlsT extends SellingControlsT {
  price: SkuPriceT
}

export interface StyleSellingControls extends SellingControlsT {
  price: StylePriceT
}

export enum PriceTypes {
  CLEARANCE = 'CLEARANCE',
  PROMOTION = 'PROMOTION',
  REGULAR = 'REGULAR'
}

export enum PriceFormatTypes {
  CLEARANCE = 'clearance',
  PROMOTION = 'promotion',
  REGULAR = 'regular'
}

export type SkuPriceT = {
  [key in PriceFormatTypes]?: { price: OfferPriceDetailsT }
} & { currentPriceType?: PriceTypes }

export type StylePriceT = {
  [key in PriceFormatTypes]?: OfferPriceRangeDetailsT
} & TotalPriceRangeT

type OfferPriceRangeDetailsT = {
  priceRange: MinAndMaxT
}

export type TotalPriceRangeT = {
  totalPriceRange?: MinAndMaxT
}

type MinAndMaxT = {
  min: OfferPriceDetailsT
  max: OfferPriceDetailsT
}

export type OfferPriceDetailsT = {
  currencyCode?: string
  units?: number
  nanos?: number
}

type SellingControlsProductAttributesT = { isOnlinePurchasable: boolean }

type SkuIdsT = { rmsSku: RmsSkuT }

type RmsSkuT = { id: string }

export type EnticementDetailsT = {
  type: string
}

// https://confluence.nordstrom.com/display/OFFER/Enticements
export enum ANNIVERSARY_ENTICEMENTS {
  'ANNIVERSARY_BEAUTY_EXCLUSIVE' = 'ANNIVERSARY_BEAUTY_EXCLUSIVE',
  'ANNIVERSARY_EARLY_ACCESS' = 'ANNIVERSARY_EARLY_ACCESS',
  'ANNIVERSARY_GROOMING_EXCLUSIVE' = 'ANNIVERSARY_GROOMING_EXCLUSIVE',
  'ANNIVERSARY_SALE' = 'ANNIVERSARY_SALE'
}

export interface OfferStyleSellingControls extends SellingControlsT {
  availability: {
    isAvailable: boolean
  }
}

export type SkusT = {
  productAttributes: OfferProductAttributesT
  sellingControls: SkuSellingControlsT[]
  ids: {
    rmsSku: {
      id: string
    }
  }
}

export type OfferProductAttributesT = {
  size: {
    name: string
  }
  color: {
    name: string
  }
  availability: {
    isAvailable: boolean
  }
}

export type OfferStyleProductT = {
  sellingControls: StyleSellingControls[]
  skus: SkusT[]
  productAttributes: ProductAttributesT
  mediaExperiences: MediaExperiencesT
  ids: {
    rmsStyleGroup: { id: string }
  }
  price?: StylePriceT
}

type SkuDetailsT = {
  color?: string
  isAvailable?: boolean
  rmsSku?: string
  size?: string
  price: SkuPriceT
}

export type StyleProductT = {
  productName?: string
  description?: string
  imageUrl?: string
  brandName?: string
  itemNumber?: string
  price?: StylePriceT
  skus: SkuDetailsT[]
}
