import { StylePriceT } from 'types/Product'
import { formatProductPrice } from 'utils/formatPrice'

type MinAndMaxT = { min: string; max: string }
type DisplayPriceT = Record<string, MinAndMaxT>

export const formatStylePrices = (stylePrice: StylePriceT): DisplayPriceT => {
  const formattedPrices: DisplayPriceT = {}

  if (stylePrice.clearance) {
    formattedPrices.clearance = {
      min: formatProductPrice(
        stylePrice.clearance.priceRange.min.units,
        stylePrice.clearance.priceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.clearance.priceRange.max.units,
        stylePrice.clearance.priceRange.max.nanos
      )
    }
  }

  if (stylePrice.promotion) {
    formattedPrices.promotion = {
      min: formatProductPrice(
        stylePrice.promotion.priceRange.min.units,
        stylePrice.promotion.priceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.promotion.priceRange.max.units,
        stylePrice.promotion.priceRange.max.nanos
      )
    }
  }

  if (stylePrice.regular) {
    formattedPrices.regular = {
      min: formatProductPrice(
        stylePrice.regular.priceRange.min.units,
        stylePrice.regular.priceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.regular.priceRange.max.units,
        stylePrice.regular.priceRange.max.nanos
      )
    }
  }

  if (stylePrice.totalPriceRange) {
    formattedPrices.totalPriceRange = {
      min: formatProductPrice(
        stylePrice.totalPriceRange.min.units,
        stylePrice.totalPriceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.totalPriceRange.max.units,
        stylePrice.totalPriceRange.max.nanos
      )
    }
  }

  return formattedPrices
}

export const shouldShowPriceRange = (price: MinAndMaxT): boolean => {
  return !!price.min && !!price.max && price.min !== price.max
}
