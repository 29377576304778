import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, Typography, useTheme } from '@mui/material'
import { SetStateAction, useState } from 'react'
import { BudgetRangeT } from 'types/Curation'
import CategoryCarousel from './CategoryCarousel'
import { emDashCharacter } from 'pages/constants'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

export const RECS_ERROR_TITLE = `Issue getting recommendations ${emDashCharacter}`

const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

interface PropsT {
  onClickForPDP: (webStyleId: string) => void
  curationId: number
  occasions?: string[]
  departments?: string[]
  budgetRange?: BudgetRangeT
}

const StartBoardRecommendations = ({
  onClickForPDP,
  curationId,
  occasions,
  departments,
  budgetRange
}: PropsT): JSX.Element => {
  const theme = useTheme()
  const [tabPosition, setTabPosition] = useState('0')
  const { isInitialRecTrayEnabled } = useFeatureFlags()

  if (
    !isInitialRecTrayEnabled?.active ||
    (occasions?.length === 0 && departments?.length === 0)
  ) {
    return <></>
  }

  const handleTabChange = (value: SetStateAction<string>) => {
    setTabPosition(value)
  }

  const recommendationCategories = [
    'All',
    'Tops',
    'Bottoms',
    'Shoes',
    'Accessories'
  ]
  const maxBudget = Math.max(
    Number(budgetRange?.tops),
    Number(budgetRange?.bottoms),
    Number(budgetRange?.shoes)
  )
  const budgetPreference = [
    maxBudget,
    budgetRange?.tops,
    budgetRange?.bottoms,
    budgetRange?.shoes,
    maxBudget
  ]
  const departmentsSearchTerm = departments?.join(' ')
  let occasionsSearchTerm = occasions?.join(' ')
  occasionsSearchTerm = occasionsSearchTerm?.includes('Weekend')
    ? 'Casual wear'
    : occasionsSearchTerm

  const recommendationSearchTerms = [
    `${departmentsSearchTerm} ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Tops ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Bottoms ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Shoes ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Accessories ${occasionsSearchTerm}`
  ]

  const occasionsString = occasions?.join(' ')

  return (
    <Box p={2} mb={3} sx={{ backgroundColor: theme.palette.grey[50] }}>
      <Typography variant="subtitle2" fontWeight={'bold'}>
        Items for {occasionsString}
      </Typography>
      <Typography variant="subtitle2" fontWeight={'regular'}>
        Add items for {occasionsString}
      </Typography>
      <TabContext value={tabPosition}>
        <TabList
          onChange={(_e, value) => handleTabChange(value)}
          variant="scrollable"
          sx={{ mb: 2 }}
        >
          {recommendationCategories.map((rec, index) => (
            <Tab key={index} value={index.toString()} label={capitalize(rec)} />
          ))}
        </TabList>
        {recommendationCategories.map((rec, index) => (
          <TabPanel key={index} value={index.toString()} sx={{ p: 0 }}>
            <CategoryCarousel
              onClickForPDP={onClickForPDP}
              curationId={curationId}
              searchTerm={recommendationSearchTerms[index]}
              budgetRange={budgetPreference[index]}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

export default StartBoardRecommendations
