import { ReactElement } from 'react'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import RecentItems from './components/RecentItems'
import RecentItemsNavigation from './components/RecentItemsNavigation'

export type PropsT = {
  handleAddItem: () => void
  handleClickForPdp: (webStyleId: string) => void
  handleClickForMiniPDP: (webStyleId: string) => void
  onClose: () => void
  shopperId: string
  token: string
  refetchToken: () => void
  open: boolean
  isEdit: boolean | null
}

const RecentItemsDrawer = (props: PropsT): ReactElement | null => {
  const {
    handleAddItem,
    handleClickForPdp,
    handleClickForMiniPDP,
    onClose,
    refetchToken,
    shopperId,
    token,
    open,
    isEdit
  } = props

  return (
    <Drawer onClose={onClose} open={open}>
      <BoxWithGladlySpace sx={{ px: 2 }}>
        <RecentItemsNavigation title="Recent Items" onClick={onClose} />
        <RecentItems
          handleAddItem={handleAddItem}
          handleClickForPdp={handleClickForPdp}
          handleClickForMiniPDP={handleClickForMiniPDP}
          shopperId={shopperId}
          token={token}
          refetchToken={refetchToken}
          isEdit={isEdit}
        />
      </BoxWithGladlySpace>
    </Drawer>
  )
}

export default RecentItemsDrawer
